import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const DonatePattern = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="855"
        height="1147"
        viewBox="0 0 855.51 1147.812"
        initial="hidden"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        whileInView="visible"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M348.078 522.203L348.078 359.176C348.078 352.741 353.259 347.506 359.693 347.438C404.813 346.961 441.644 383.405 441.644 428.527L441.644 508.702C441.644 521.855 430.908 532.479 417.755 532.34L357.478 531.702C352.27 531.647 348.078 527.41 348.078 522.203Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="418.16"
          y="362.07"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M390.913 624.066L464.695 624.066C469.941 624.066 474.195 628.32 474.195 633.566L474.195 705.596C474.195 712.243 468.805 717.633 462.158 717.633L460.81 717.633C415.671 717.633 379.078 681.04 379.078 635.901C379.078 629.365 384.377 624.066 390.913 624.066Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M452.759 251.566L409.496 251.566C361.188 251.566 322.027 212.405 322.027 164.098C322.027 160.73 324.757 158 328.125 158L452.602 158C457.848 158 462.102 162.254 462.102 167.5L462.102 242.224C462.102 247.384 457.919 251.566 452.759 251.566Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="494.102"
          y="95"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          transform="rotate(90 494.102 95)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M388.42 493.844L431.684 493.844C479.991 493.844 519.152 533.005 519.152 581.312C519.152 584.68 516.422 587.41 513.055 587.41L388.578 587.41C383.331 587.41 379.078 583.157 379.078 577.91L379.078 503.186C379.078 498.026 383.261 493.844 388.42 493.844Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M317.07 658.011L317.07 707.676C317.07 755.983 356.231 795.144 404.539 795.144C407.906 795.144 410.636 792.414 410.636 789.047L410.636 664.57C410.636 659.324 406.383 655.07 401.136 655.07L320.011 655.07C318.387 655.07 317.07 656.387 317.07 658.011Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M547.602 390.379L547.602 391.821C547.602 395.248 544.823 398.027 541.396 398.027L463.535 398.027C458.289 398.027 454.035 393.773 454.035 388.527L454.035 309.008C454.035 305.64 456.765 302.91 460.133 302.91C508.441 302.91 547.602 342.071 547.602 390.379Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M567.602 780.379L567.602 781.821C567.602 785.248 564.823 788.027 561.396 788.027L483.535 788.027C478.289 788.027 474.035 783.773 474.035 778.527L474.035 699.008C474.035 695.64 476.765 692.91 480.133 692.91C528.441 692.91 567.602 732.071 567.602 780.379Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M317.07 339.468L317.07 340.911C317.07 344.338 319.849 347.116 323.276 347.116L401.136 347.116C406.383 347.116 410.636 342.863 410.636 337.616L410.636 258.098C410.636 254.73 407.906 252 404.539 252C356.231 252 317.07 291.161 317.07 339.468Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M379.633 540.547L379.633 615.113C379.633 620.36 375.38 624.613 370.133 624.613L295.567 624.613C290.32 624.613 286.067 620.36 286.067 615.113L286.067 540.547C286.067 535.3 290.32 531.047 295.567 531.047L370.133 531.047C375.38 531.047 379.633 535.3 379.633 540.547Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M338.763 440.699L295.5 440.699C247.192 440.699 208.031 401.538 208.031 353.231C208.031 349.863 210.761 347.133 214.129 347.133L338.605 347.133C343.852 347.133 348.105 351.386 348.105 356.633L348.105 431.357C348.105 436.516 343.923 440.699 338.763 440.699Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M390.133 743.434L597.875 743.434C602.346 743.434 605.971 747.059 605.971 751.531C605.971 798.734 567.706 837 520.502 837L390.133 837C384.886 837 380.633 832.746 380.633 827.5L380.633 752.934C380.633 747.687 384.886 743.434 390.133 743.434Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />

        <m.path
          d="M712.922 315.797L712.922 478.824C712.922 485.259 707.741 490.494 701.307 490.562C656.187 491.039 619.356 454.595 619.356 409.473L619.356 329.298C619.356 316.145 630.092 305.521 643.245 305.66L703.522 306.298C708.73 306.353 712.922 310.59 712.922 315.797Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="642.84"
          y="475.93"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(-180 642.84 475.93)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M670.087 213.934L596.305 213.934C591.059 213.934 586.805 209.68 586.805 204.434L586.805 132.404C586.805 125.757 592.195 120.367 598.842 120.367L600.19 120.367C645.329 120.367 681.922 156.96 681.922 202.099C681.922 208.635 676.623 213.934 670.087 213.934Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M608.241 586.434L651.504 586.434C699.812 586.434 738.973 625.595 738.973 673.902C738.973 677.27 736.243 680 732.875 680L608.398 680C603.152 680 598.898 675.746 598.898 670.5L598.898 595.776C598.898 590.616 603.081 586.434 608.241 586.434Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="566.898"
          y="743"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          transform="rotate(-90 566.898 743)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M672.579 344.156L629.316 344.156C581.009 344.156 541.848 304.995 541.848 256.688C541.848 253.32 544.578 250.59 547.945 250.59L672.422 250.59C677.669 250.59 681.922 254.843 681.922 260.09L681.922 334.814C681.922 339.974 677.739 344.156 672.579 344.156Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M743.93 179.989L743.93 130.324C743.93 82.0166 704.769 42.8556 656.461 42.8556C653.094 42.8556 650.364 45.5856 650.364 48.9532L650.364 173.43C650.364 178.676 654.617 182.93 659.864 182.93L740.989 182.93C742.613 182.93 743.93 181.613 743.93 179.989Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M513.398 447.621L513.398 446.179C513.398 442.752 516.177 439.973 519.604 439.973L597.465 439.973C602.711 439.973 606.965 444.227 606.965 449.473L606.965 528.992C606.965 532.36 604.235 535.09 600.867 535.09C552.559 535.09 513.398 495.929 513.398 447.621Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M493.398 57.6214L493.398 56.1792C493.398 52.7519 496.177 49.9734 499.604 49.9734L577.465 49.9734C582.711 49.9734 586.965 54.2267 586.965 59.4734L586.965 138.992C586.965 142.36 584.235 145.09 580.867 145.09C532.559 145.09 493.398 105.929 493.398 57.6214Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M743.93 498.532L743.93 497.089C743.93 493.662 741.151 490.884 737.724 490.884L659.864 490.884C654.617 490.884 650.364 495.137 650.364 500.384L650.364 579.902C650.364 583.27 653.094 586 656.461 586C704.769 586 743.93 546.839 743.93 498.532Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M681.367 297.453L681.367 222.887C681.367 217.64 685.62 213.387 690.867 213.387L765.433 213.387C770.68 213.387 774.933 217.64 774.933 222.887L774.933 297.453C774.933 302.7 770.68 306.953 765.433 306.953L690.867 306.953C685.62 306.953 681.367 302.7 681.367 297.453Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M722.237 397.301L765.5 397.301C813.808 397.301 852.969 436.462 852.969 484.769C852.969 488.137 850.239 490.867 846.871 490.867L722.395 490.867C717.148 490.867 712.895 486.614 712.895 481.367L712.895 406.643C712.895 401.484 717.077 397.301 722.237 397.301Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M670.867 94.5664L463.125 94.5664C458.654 94.5664 455.029 90.9413 455.029 86.4695C455.029 39.2661 493.294 1.00027 540.498 1.00027L670.867 1.00027C676.114 1.00027 680.367 5.25357 680.367 10.5003L680.367 85.0664C680.367 90.3131 676.114 94.5664 670.867 94.5664Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M428.203 1008.92H265.176C258.741 1008.92 253.506 1003.74 253.438 997.308C252.961 952.189 289.405 915.357 334.527 915.357H414.702C427.855 915.357 438.479 926.094 438.34 939.246L437.702 999.524C437.647 1004.73 433.41 1008.92 428.203 1008.92Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="268.07"
          y="938.841"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(-90 268.07 938.841)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M530.066 966.086V892.304C530.066 887.057 534.32 882.804 539.566 882.804H611.596C618.243 882.804 623.633 888.193 623.633 894.841V896.189C623.633 941.328 587.04 977.92 541.901 977.92C535.365 977.92 530.066 972.622 530.066 966.086Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M157.566 904.239V947.503C157.566 995.81 118.405 1034.97 70.0979 1034.97C66.7303 1034.97 64.0003 1032.24 64.0003 1028.87L64.0003 904.397C64.0003 899.15 68.2536 894.897 73.5003 894.897H148.224C153.384 894.897 157.566 899.08 157.566 904.239Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="1"
          y="862.897"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M399.844 968.578V925.315C399.844 877.008 439.005 837.847 487.312 837.847C490.68 837.847 493.41 840.577 493.41 843.944V968.421C493.41 973.667 489.157 977.921 483.91 977.921H409.186C404.026 977.921 399.844 973.738 399.844 968.578Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M564.011 1039.93H613.676C661.983 1039.93 701.144 1000.77 701.144 952.463C701.144 949.095 698.414 946.365 695.047 946.365H570.57C565.324 946.365 561.07 950.618 561.07 955.865V1036.99C561.07 1038.61 562.387 1039.93 564.011 1039.93Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M296.379 809.397H297.821C301.248 809.397 304.027 812.175 304.027 815.603V893.463C304.027 898.71 299.773 902.963 294.527 902.963H215.008C211.64 902.963 208.91 900.233 208.91 896.865C208.91 848.558 248.071 809.397 296.379 809.397Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M686.379 789.397H687.821C691.248 789.397 694.027 792.175 694.027 795.603V873.463C694.027 878.71 689.773 882.963 684.527 882.963H605.008C601.64 882.963 598.91 880.233 598.91 876.865C598.91 828.558 638.071 789.397 686.379 789.397Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M245.468 1039.93H246.911C250.338 1039.93 253.116 1037.15 253.116 1033.73V955.865C253.116 950.618 248.863 946.365 243.616 946.365H164.098C160.73 946.365 158 949.095 158 952.463C158 1000.77 197.161 1039.93 245.468 1039.93Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M446.547 977.369H521.113C526.36 977.369 530.613 981.623 530.613 986.869V1061.44C530.613 1066.68 526.36 1070.94 521.113 1070.94H446.547C441.3 1070.94 437.047 1066.68 437.047 1061.44V986.869C437.047 981.623 441.3 977.369 446.547 977.369Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M346.699 1018.24V1061.5C346.699 1109.81 307.538 1148.97 259.231 1148.97C255.863 1148.97 253.133 1146.24 253.133 1142.87V1018.4C253.133 1013.15 257.386 1008.9 262.633 1008.9H337.357C342.516 1008.9 346.699 1013.08 346.699 1018.24Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M649.434 966.869V759.128C649.434 754.656 653.059 751.031 657.531 751.031C704.734 751.031 743 789.297 743 836.5V966.869C743 972.116 738.746 976.369 733.5 976.369H658.934C653.687 976.369 649.434 972.116 649.434 966.869Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

DonatePattern.defaultProps = {};

export default DonatePattern;
